const tokyoRaw = [
  {
    "number": 1,
    "title": "Bulgari Hotel Tokyo",
    "description": "Unveiled in April 2023, Bulgari Hotel combines modern luxury and Italian elegance, a vision supported by Ambassador Anne Hathaway.",
    "price": "$1,993",
    "points": 4.9,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/bulgari-tokyo"
  },
  {
    "number": 2,
    "title": "Aman Tokyo",
    "description": "From the heights of the Otemachi Tower, Aman Tokyo is ideally placed for exploring the capital city’s many attractions.",
    "price": "$1,959",
    "points": 4.7,
    "category": "International",
    "link_destination": "official",
    "base_url": "https://www.aman.com/hotels/aman-tokyo"
  },
  {
    "number": 3,
    "title": "Four Seasons Hotel Tokyo at Otemachi",
    "description": "Soaring above the heart of the capital and offering expansive Imperial Palace views, Four Seasons delivers next-generation luxury.",
    "price": "$907",
    "points": 4.5,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/four-seasons-tokyo-at-otemachi"
  },
  {
    "number": 4,
    "title": "The Ritz-Carlton, Tokyo",
    "description": "Known as one of the modern big 3 hotels in Tokyo, Ritz Carton Hotel is located in the center of Roppongi, the capital’s entertainment hub.",
    "price": "$1,079",
    "points": 4.6,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/the-ritz-carlton-tokyo"
  },
  {
    "number": 5,
    "title": "Palace Hotel Tokyo",
    "description": "With a moat-side setting just opposite the Imperial Palace gardens, Palace Hotel is a harmonious blend of heritage and comfort.",
    "price": "$869",
    "points": 4.6,
    "category": "Local",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/palace-tokyo"
  },
  {
    "number": 6,
    "title": "Andaz Tokyo Toranomon Hills",
    "description": "Opened in June 2014, Andaz sits atop of a 52-story skyscraper that’s part of a revolutionary complex project in the Toranomon District.",
    "price": "$957",
    "points": 4.4,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/andaz-tokyo"
  },
  {
    "number": 7,
    "title": "The Peninsula Tokyo",
    "description": "Within minutes' walk of the shopping capital of Ginza, The Peninsula Tokyo is one of the modern 3 big hotels in Tokyo.",
    "price": "$830",
    "points": 4.5,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/the-peninsula-tokyo"
  },
  {
    "number": 8,
    "title": "Shangri-La Hotel Tokyo",
    "description": "Located adjacent to Tokyo Station, Shangri-La Tokyo offers a tranquil and elegant atmosphere with panoramic views.",
    "price": "$596",
    "points": 4.5,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/shangri-la-tokyo"
  },
  {
    "number": 9,
    "title": "The Tokyo Station Hotel",
    "description": "Timeless charm meets central convenience, as the red-hued building has been the heart and transportation hub of the city for over 100 years.",
    "price": "$428",
    "points": 4.4,
    "category": "Local",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/tokyo-station"
  },
  {
    "number": 10,
    "title": "The Westin Tokyo",
    "description": "Classic European décor adorns each room, accompanied by the Heavenly Bed to ensure you leave feeling better than when you arrived.",
    "price": "$391",
    "points": 4.2,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/the-westin-tokyo"
  },
  {
    "number": 11,
    "title": "The Okura Tokyo",
    "description": "Journey through tradition in hotels as the Okura has been opened from 1962 and continuosly evolved to provide the highest standards.",
    "price": "$398",
    "points": 4.3,
    "category": "Local",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/the-okura-tokyo"
  },
  {
    "number": 12,
    "title": "Grand Hyatt Tokyo",
    "description": "With its central location connected to Roppongi Hills which houses 200 shops and restaurants, a cinema, a museum and an observatory.",
    "price": "$537",
    "points": 4.4,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/grand-hyatt-tokyo"
  },
  {
    "number": 13,
    "title": "Hotel Gajoen Tokyo",
    "description": "Experience an enchanting stay at  Gajoen, a 1920s wedding complex turned contemporary boutique hotel, with many original features.",
    "price": "$362",
    "points": 4.5,
    "category": "Boutique",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/meguro-gajyoen"
  },
  {
    "number": 14,
    "title": "Imperial Hotel Tokyo",
    "description": "Witness a Tokyo icon, from hosting the Royal family wedding to housing Michael Jackson the Imperial Hotel is truly renowned.",
    "price": "$262",
    "points": 4.2,
    "category": "Local",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/imperial-tokyo"
  },
  {
    "number": 15,
    "title": "Cerulean Tower Tokyu Hotel",
    "description": "Offering 408 spacious rooms, spreading over 40 floors, all of which offers unobstructed views of the glittering nightscape and Mount Fuji.",
    "price": "$281",
    "points": 4.3,
    "category": "Local",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/cerulean-tower-tokyu"
  },
  {
    "number": 16,
    "title": "Hotel New Otani",
    "description": "Surrounded by a beautiful 400-year old Japanese garden, New Otani is located in central Tokyo and offers comfortable accommodations.",
    "price": "$250",
    "points": 4.1,
    "category": "Local",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/new-otani-executive-house-zen"
  },
  {
    "number": 17,
    "title": "Mandarin Oriental, Tokyo",
    "description": "Known as one of the modern big 3 hotels in Tokyo, Mandarin is located in historical centre of Tokyo, Nihonbashi, formerly known as Edo.",
    "price": "$688",
    "points": 4.5,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/mandarin-oriental-tokyo"
  },
  {
    "number": 18,
    "title": "Park Hyatt Tokyo",
    "description": "Guests can enjoy a unique dining experience with panoramic views from the iconic New York Grill & Bar on the 52nd floor.",
    "price": "$1,208",
    "points": 4.5,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/park-hyatt-tokyo"
  },
  {
    "number": 19,
    "title": "Hotel Chinzanso Tokyo",
    "description": "Set within a historic Japanese garden, Hotel Chinzanso Tokyo offers spacious rooms with garden or city views.",
    "price": "$309",
    "points": 3.9,
    "category": "Local",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/chinzanso-tokyo"
  },
  {
    "number": 20,
    "title": "Four Seasons Hotel Tokyo At Marunouchi",
    "description": "Directly accessible from JR Tokyo Station, Four Seasons Hotel Tokyo at Marunouchi offers a quiet luxurious accommodations.",
    "price": "$884",
    "points": 4.5,
    "category": "International",
    "link_destination": "booking",
    "base_url": "https://www.booking.com/hotel/jp/four-seasons-tokyo-at-marunouchi"
  }
];

export default tokyoRaw;