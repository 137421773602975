import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Layout from "components/common/Layout";
import Header from "components/hotels/Header";
import Card from "components/hotels/Card";
import Action from "components/hotels/Action";
import Footer from "components/hotels/Footer";

import tokyoRaw from "enums/tokyo";

const Tokyo = () => {
  const [arr, setArr] = useState([]);
  const raw = tokyoRaw.map(elem => ({
    ...elem,
    url: elem.link_destination === 'official'?
      elem.base_url:
      `${elem.base_url}.en.html?aid=2394073&no_rooms=1&group_adults=2`
  }))
  const exploreMore = () => {
    setArr(raw);
  }
  useEffect(() => {
    setArr(raw.filter(elem => elem.number <= 10));
  }, []);
  return (
    <Layout title='Tokyo | OMAKASE HOTEL'>
      <Container>
        <Header />
        <Body>
          {arr.map(elem => (
            <Card
              key={elem.number}
              title={elem.title}
              description={elem.description}
              price={elem.price}
              points={elem.points}
              category={elem.category}
              url={elem.url}
            />
          ))}
          {arr.length <= 10 && <Action
            exploreMore={exploreMore}
          />}
        </Body>
        <Footer />
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  padding-bottom: 50px;
`;

const Body = styled.div`
  padding: 0px 20px 40px 20px;
`;

export default Tokyo;
