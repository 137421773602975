import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import PrivacyPolicy from "views/privacy-policy";
import TermsOfServices from "views/terms-of-services";
import Tokyo from "views/tokyo";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-services" element={<TermsOfServices />} />
        <Route path="*" element={<Tokyo />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;