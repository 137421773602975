const termsOfServicesArr = [
  {
    title: 'Terms of use - the gist',
    text:
`We the Omakase provide a service called the Omakase hotel ("the Service") and would love for you to use it. The technology behind the Service and its operation is provided by ThirdPlace inc (3P). You can use the Service to post your ideas or opinions. However, be responsible in what you contribute. In particular, make sure that none of the prohibited items listed below are included in any contributions you make to the Service or get linked to from the Service (things like spam, viruses, or hate content).

If you find any content that you believe violates these Terms of Use, please contact us at support@omakase.com.`
  },
  {
    title: 'Terms of use - the details',
    text:
`Please read these Terms of Use carefully before accessing or using the Service. By accessing or using any part of the Service, you agree to become bound by these Terms of Use. If you do not agree to all the terms and conditions of these Terms of Use, then do not access or use the Service. The Service is available only to individuals who are at least thirteen (13) years old.`
  },
  {
    title: '1. Responsibility for Contributions.',
    text:
`If you place comments or ideas on the Service, post material to the Service, post links on the Service, or otherwise make (or allow any third party to make) material available by means of the Service (any such material, "Content"), you are entirely responsible for the content of, and any harm resulting from, that Content. That is the case regardless of whether the Content in question constitutes text, graphics, an audio file, or computer software.

By making Content available, you represent and warrant that:

1.the downloading, copying and use of the Content will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark or trade secret rights, of any third party;
2.if your employer has rights to intellectual property you create, you have either (i) received permission from your employer to post or make available the Content, including but not limited to any software, or (ii) secured from your employer a waiver as to all rights in or to the Content;
3.you have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through any required terms to anyone else who accesses the Content;
4.the Content does not contain or install any viruses, worms, malware, Trojan horses or other harmful or destructive content;
5.the Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);
6.the Content is not pornographic, libelous or defamatory, does not contain threats or incite violence towards individuals or entities, and does not violate the privacy or publicity rights of any third party;

By submitting Content to the Service, you grant us and the operator of the Service (3P) a world-wide, royalty-free, and non-exclusive license to reproduce, modify, adapt and publish the Content solely for the purpose of displaying, distributing and promoting the Service. If you delete Content, we will use reasonable efforts to remove it from the Service, but you acknowledge that caching or other references to the Content can mean Content may not be made immediately unavailable.

If you submit ideas or suggestions, we will be free to use them and any similar ideas or suggestions, without any credit or recompense to you. However, we may give you credit for the suggestion/idea.

Without limiting any of those representations or warranties, we have the right (though not the obligation) to, in our sole discretion (i) refuse or remove any Content that, in our reasonable opinion, violates any of our or Delib's policies or is in any way harmful or objectionable, or (ii) terminate or deny access to and use of the Services to any individual or entity for any reason, in our sole discretion.`
  },
  {
    title: '2. Responsibility of Service Visitors',
    text:
`We have not reviewed, and cannot review, all of the material, including computer software, posted to the Service, and cannot therefore be responsible for that material's content, use or effects. By operating the Service, we do not represent or imply that we endorse the material there posted, or that we believe such material to be accurate, useful or non-harmful. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. Despite our efforts, the Service may contain content that is offensive, indecent, or otherwise objectionable, as well as content containing technical inaccuracies, typographical mistakes, and other errors. The Service may also contain material that violates the privacy or publicity rights, or infringes the intellectual property and other proprietary rights, of third parties, or the downloading, copying or use of which is subject to additional terms and conditions, stated or unstated. We and 3P disclaim any responsibility for any harm resulting from the use by visitors of the Service, or from any downloading by those visitors of content there posted.`
  },
  {
    title: '3. Content Posted on Other Websites',
    text:
`We have not reviewed, and cannot review, all of the material, including computer software, made available through the websites and webpages to which the Service links, and that link to the Service. We do not have any control over those third party websites and webpages, and we are not responsible for their contents or their use. By linking to a third party website or webpage, we do not represent or imply that we endorse such website or webpage. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content. We disclaim any responsibility for any harm resulting from your use of third party websites and webpages.`
  },
  {
    title: '4. Copyright Infringement Policy',
    text:
`As we ask others to respect our intellectual property rights, we respect the intellectual property rights of others. If you believe that material located on or linked to by the Service violates your or a third party's copyright, you are encouraged to notify us. We will respond to all such notices, including as required or appropriate by removing the infringing material or disabling all links to the infringing material. In the case of a visitor who may infringe or repeatedly infringes the copyrights or other intellectual property rights of ours or others, we may, in our discretion, terminate or deny access to and use of the Service.`
  },
  {
    title: '5. Intellectual Property.',
    text:
`These Terms of Use do not transfer to you any intellectual property rights in the Service, and all right, title and interest in and to such property will remain solely with us or 3P. Omakase trade marks/logos, 3P trade marks/logos and all other trademarks, service marks, graphics and logos used in connection with the Service, are trademarks or registered trademarks of the Omakase and/or 3P. Other trademarks, service marks, graphics and logos used in connection with the Service may be the trademarks of other third parties. Your use of the Service grants you no right or license to reproduce or otherwise use any Omakase, 3P or third-party trademarks.`
  },
  {
    title: '6. Changes',
    text:
`We reserve the right, at our sole discretion, to modify or replace any part of these Terms of Use. It is your responsibility to check them periodically for changes. Your continued use of or access to the Service following the posting of any changes to these Terms of Use constitutes acceptance of those changes. We may also, in the future, offer new services and/or features through the Service (including, the release of new tools and resources). Such new features and/or services shall be subject to these Terms of Use.`
  },
  {
    title: '7. Termination',
    text:
`We may terminate your access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately. All provisions of these Terms of Use which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.`
  },
  {
    title: '8. Disclaimer of Warranties',
    text:
`The Service is provided "as is". Omakase, 3P and its suppliers and licensors and parent or associated companies hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, satisfactory quality, fitness for a particular purpose and non-infringement. Neither Omakase, 3P nor its suppliers and licensors, makes any warranty that the Service will be error free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, the Service at your own discretion and risk.`
  },
  {
    title: '9. Limitation of Liability',
    text:
`In no event will the Omakase, 3P, or its suppliers or licensors, be liable with respect to any subject matter of these Terms of Use under any contract, negligence, statutory or other legal or equitable theory for:

1.any special, incidental or consequential damages, including but not limited to loss of profits, loss of revenue, loss of business, howsoever caused and whether direct or indirect;
2.the cost of procurement or substitute products or services;
3.for interruption of use or loss or corruption of data;
4.for any amounts that exceeds $350 Australian

Neither Omakase or 3P shall have liability for any failure or delay due to matters beyond their reasonable control. The foregoing shall not apply to the extent prohibited by applicable law.`
  },
  {
    title: '10. General Representation and Warranty',
    text:
`You represent and warrant that (i) your use of the Service will be in strict accordance with the Privacy Policy, these Terms of Use and with all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from Australia or the country in which you reside) and (ii) your use of the Service will not infringe or misappropriate the intellectual property rights of any third party.`
  },
  {
    title: '11. Indemnification',
    text:
`You agree to indemnify and hold harmless Omakase, 3P, their contractors, and licensors, and their respective directors, officers, employees and agents from and against any and all liabilities, losses, damages, costs, claims and expenses, including lawyers' fees, arising out of your use of the Service, including but not limited to your violation of these Terms of Service.`
  },
  {
    title: '12. Miscellaneous',
    text:
`These Terms of Service constitute the entire agreement between the Omakase and you concerning the subject matter hereof, and they may only be modified by a written amendment signed by an authorized executive of the Omakase, or by the posting by the Omakase of a revised version. Except to the extent applicable law, if any, provides otherwise, these Terms of of Use, any access to or use of the Service will be governed by the laws of the Australian Capital Territory excluding its conflict of law provisions, and the proper venue for any disputes arising out of or relating to any of the same will be the Australian courts, except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may be brought in any competent court). If any part of these Terms of Use is held invalid or unenforceable, that part will be construed to reflect the parties' original intent, and the remaining portions will remain in full force and effect. A waiver by either party of any term or condition of these Terms of Use or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof. You may assign your rights under these Terms of Use to any party that consents to, and agrees to be bound by, its terms and conditions; the Omakase may assign its rights under these Terms of Use without condition. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.`
  },
];

export default termsOfServicesArr;