import React from "react";
import styled from "styled-components";

import * as Scroll from "react-scroll";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const scroll = Scroll.animateScroll;
  return (
    <Container>
      <MainWrapper id="home">
        <Main onClick={() => scroll.scrollToTop({ duration: 600 })}>
          <MainIcon>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_3004_1282)">
                <path d="M10.5 20V14.5H13.5V20V20.5H14H19H19.5V20V12V11.8029L19.3655 11.6588L12.3655 4.15884L12 3.7672L11.6345 4.15884L4.63447 11.6588L4.5 11.8029V12V20V20.5H5H10H10.5V20Z" stroke="black"/>
              </g>
              <defs>
                <clipPath id="clip0_3004_1282">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </MainIcon>
          <MainSub>Home</MainSub>
        </Main>
      </MainWrapper>
      <Sub>
        <SubText onClick={() => navigate("/privacy-policy")}>Privacy Policy</SubText>
        <SubText onClick={() => navigate("/terms-of-services")}>Terms of Services</SubText>
      </Sub>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 8px;
`;

const MainWrapper = styled.div`
`;

const Main = styled.div`
  border-radius: 20px;
  background: rgba(196, 196, 196, 0.15);
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MainIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const MainSub = styled.div`
  margin-top: 5px;
  color: #000;
  font-size: 12px;
  font-weight: 600;
`;

const Sub = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

const SubText = styled.div`
  margin: 0px 15px;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
`;

export default Footer;
