import React from "react";
import styled from "styled-components";

const Action = props => {
  const {
    exploreMore
  } = props;
  return (
    <Container>
      <Label>Continue exploring hotels</Label>
      <ActionButton
        onClick={() => exploreMore()}
        id='explore'
      >
        Show more
      </ActionButton>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  color: #222;
  font-size: 18px;
  font-weight: 600;
`;

const ActionButton = styled.div`
  display: flex;
  width: 132px;
  margin-top: 20px;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Shade-02, #222);
  cursor: pointer;
  color: var(--Shade-01, #FFF);
  font-size: 16px;
  font-weight: 600;
`;

export default Action;
