import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <React.Fragment>
      <Container>
        <Logo src="logo-group.png" />
      </Container>
    </React.Fragment>
  );
};

const Container = styled.div`
  display: flex;
  padding: 8px 20px;
  align-items: center;
  height: 52px;
  border-bottom: 1px solid var(--Neutral-03, #DDD);
  background: var(--Shade-01, #FFF);
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12);
  z-index: 100;
`;

const Logo = styled.img`
  width: 175px;
  display: block;
`;

export default Header;
