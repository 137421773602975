const privacyPolicyArr = [
  {
    title: 'Privacy Policy',
    text:
`Omakase values and respects the privacy of the people we deal with. Omakase is committed to protecting your privacy and complying with the Privacy Act 1988 (Cth) (Privacy Act) and other applicable privacy laws and regulations. This Privacy Policy (Policy) describes how we collect, hold, use and disclose your personal information, and how we maintain the quality and security of your personal information.`
  },
  {
    title: 'What is personal information?',
    text:
`“Personal information” means any information or opinion, whether true or not, and whether recorded in a material form or not, about an identified individual or an individual who is reasonably identifiable. In general terms, this includes information or an opinion that personally identifies you either directly (e.g. your name) or indirectly.`
  },
  {
    title: 'What personal information do we collect?',
    text:
`The personal information we collect about you depends on the nature of your dealings with us or what you choose to share with us.

The personal information we collect about you may include:

● name
● mailing or street address
● date of birth
● email address
● phone number

Under certain circumstances, Omakase may need to collect sensitive information about you. This might
include any information or opinion about your racial or ethnic origin, political opinions, political association,
religious or philosophical beliefs, membership of a trade union or other professional body, sexual preferences,
criminal record, or health information.

If we collect your sensitive information, we will do so only with your consent, if it is necessary to prevent a
serious and imminent threat to life or health, or as otherwise required or authorised by law, and we take
appropriate measures to protect the security of this information.

You do not have to provide us with your personal information. Where possible, we will give you the option to interact with us anonymously or by using a pseudonym. However, if you choose to deal with us in this way or choose not to provide us with your personal information, we may not be able to provide you with our services or otherwise interact with you.`
  },
  {
    title: 'How do we collect your personal information?',
    text:
`We collect your personal information directly from you when you:
● interact with us over the phone
● interact with us in person
● interact with us online
● participate in surveys or questionnaires
● attend a Omakase event
● subscribe to our mailing list
● apply for a position with us as an employee, contractor or volunteer`
  },
  {
    title: 'How do we use your personal information?',
    text:
`We use personal information for many purposes in connection with our functions and activities, including the
following purposes:
● provide you with information or services that you request from us
● deliver to you a more personalised experience and service offering
● improve the quality of the services we offer
● internal administrative purposes
● marketing and research purposes`
  },
  {
    title: 'Disclosure of personal information to third parties',
    text:
`We may disclose your personal information to third parties in accordance with this Policy in circumstances
where you would reasonably expect us to disclose your information. For example, we may disclose your
personal information to:
● our third party service providers (for example, our IT providers)
● our marketing providers
● our professional services advisors`
  },
  {
    title: 'How do we protect your personal information?',
    text:
`Omakase will take reasonable steps to ensure that the personal information that we hold about you is kept
confidential and secure, including by:
● having a robust physical security of our premises and databases / records;
● taking measures to restrict access to only personnel who need that personal information to effectively
provide services to you;
● having technological measures in place (for example, anti-virus software, fire walls)`
  },
  {
    title: 'Retention of personal information',
    text:
`We will not keep your personal information for longer than we need to. In most cases, this means that we will only retain your personal information for the duration of your relationship with us unless we are required to retain your personal information to comply with applicable laws, for example record-keeping obligations.`
  },
  {
    title: 'How to access and correct your personal information',
    text:
`Omakase will endeavour to keep your personal information accurate, complete and up to date.
If you wish to make a request to access and / or correct the personal information we hold about you, you should
make a request by contacting us and we will usually respond within seven (7) days.`
  },
  {
    title: 'Links to third party sites',
    text:
`Omakase websites may contain links to websites operated by third parties. If you access a third party
website through our websites, personal information may be collected by that third party website. We make no
representations or warranties in relation to the privacy practices of any third party provider or website and we
are not responsible for the privacy policies or the content of any third party provider or website. Third party
providers / websites are responsible for informing you about their own privacy practices and we encourage you
to read their privacy policies.`
  },
  {
    title: 'Inquiries and complaints',
    text:
`For complaints about how Omakase handles, processes or manages your personal information, please
contact support@omakase.com. Note we may require proof of your identity and full details of your request
before we can process your complaint.
Please allow up to seven (7) days for Omakase to respond to your complaint. It will not always be possible to
resolve a complaint to everyone’s satisfaction. `
  },
  {
    title: 'How to contact us',
    text:
`Omakase will endeavour to keep your personal information accurate, complete and up to date.
If you wish to make a request to access and / or correct the personal information we hold about you, you should
make a request by contacting us and we will usually respond within seven (7) days.

Email
support@omakase.com

Contact number
+81 050 3479 7858

Post
Attention: ThirdPlace Privacy Officer
Address: Ark Hills South Tower The 16th floor, 1-4-5, Roppongi, Minato-ku, Tokyo, Japan`
  },
];

export default privacyPolicyArr;