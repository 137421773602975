import React from "react";
import styled from "styled-components";

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Card = props => {
  return (
    <Container>
      <Carousel
        showArrows={navigator.userAgent.match(/iPhone|Android.+Mobile/)? false:true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
      >
        {[1,2,3,4,5].map((elem) => {
          return (
            <LinkWrapper href={props.url} target="_blank" rel="noopener noreferrer" key={`image${elem}`}>
              <PictureWrapper>
                  {elem === 1 && <PictureLabel
                  >
                    <PictureLabelText>
                      {props.category}
                    </PictureLabelText>
                  </PictureLabel>}
                  <Picture src={`images/hotel/tokyo/${props.title}${elem}.png`} />
              </PictureWrapper>
            </LinkWrapper>
          );
        })}
      </Carousel>
      <LinkWrapper href={props.url} target="_blank" rel="noopener noreferrer">
        <Head>
          <Title>{props.title}</Title>
          <Point>
            <PointStar>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M6.5479 0.457121C6.7283 0.075191 7.27169 0.0751906 7.4521 0.457121L9.17302 4.10039C9.2459 4.25469 9.39245 4.36116 9.56172 4.3828L13.5585 4.89366C13.9775 4.94721 14.1454 5.464 13.8379 5.7536L10.9047 8.51612C10.7805 8.63312 10.7245 8.8054 10.7562 8.97308L11.5055 12.9321C11.584 13.3471 11.1444 13.6665 10.7739 13.4635L7.24023 11.5276C7.09057 11.4456 6.90943 11.4456 6.75976 11.5276L3.22606 13.4635C2.85561 13.6665 2.416 13.3471 2.49454 12.9321L3.24375 8.97308C3.27548 8.8054 3.2195 8.63312 3.09528 8.51612L0.162117 5.7536C-0.145371 5.464 0.0225446 4.94721 0.441531 4.89366L4.43827 4.3828C4.60755 4.36116 4.7541 4.25469 4.82698 4.10039L6.5479 0.457121Z" fill="#222222"/>
              </svg>
            </PointStar>
            <PointNumber>
              {props.points}
            </PointNumber>
          </Point>
        </Head>
        <Description>{props.description}</Description>
        <Price>
          <PriceEmph>
            {props.price}
          </PriceEmph>
            night
        </Price>
      </LinkWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 35px;
`;

const LinkWrapper = styled.a`
  text-decoration: none;
`;

const PictureWrapper = styled.div`
	width: 100%;
  display: block;
  position: relative;
  &:before {
    content: "";
    padding-top: 100%;
    display: block;
  }
`;

const PictureLabel = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 20;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--Shade-01, #FFF);
`;

const PictureLabelText = styled.span`
  color: var(--Shade-02, #222);
  font-size: 14px;
  font-weight: 600;
`;

const Picture = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 0 0;
`;

const Head = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-weight: 600;
  color: var(--Shade-02, #222);
`;

const Point = styled.div`
  display: flex;
`;

const PointStar = styled.div`
  fill: var(--Shade-02, #222);
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const PointNumber = styled.div`
  color: var(--Shade-02, #222);
  font-size: 14px;
  font-weight: 400;
`;

const Description = styled.p`
  margin-top: 4px;
  color: var(--Neutral-08, #5E5E5E);
  font-weight: 400;
  word-break: break-all;
`;

const Price = styled.div`
  margin-top: 10px;
  color: var(--Shade-02, #222);
  font-weight: 400;
`;

const PriceEmph = styled.span`
  font-weight: 600;
  margin-right: 5px;
`;

export default Card;
