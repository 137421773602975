import React, { useEffect } from "react";
import styled from "styled-components";

import Layout from "components/common/Layout";
import Head from "components/policy/Head";
import Article from "components/policy/Article";

import * as Scroll from "react-scroll";

import termsOfServicesArr from "enums/terms-of-services";

const TermsOfServices = () => {
  const scroll = Scroll.animateScroll;
  useEffect(() => {
    scroll.scrollToTop({ duration: 0 })
  }, [])
  return (
    <Layout title='Terms Of Services | OMAKASE HOTEL'>
      <Container>
        <Head
          title='Terms Of Services'
          date='October 1, 2023'
        />
        <Body>
          {termsOfServicesArr.map((elem, index)  => (
            <Article
              key={`article${index + 1}`}
              title={elem.title}
              text={elem.text}
            />
          ))}
        </Body>
      </Container>
    </Layout>
  );
}

const Container = styled.div`
`;

const Body = styled.div`
  padding: 0px 35px 40px 35px;
`;

export default TermsOfServices;