import { Helmet } from "react-helmet-async";
import styled from "styled-components";

const Layout = props => {
  return (
    <Container>
      {props.title && <Helmet title={props.title} />}
      {props.children}
    </Container>
  );
};

const Container = styled.div`
`;

export default Layout;
